import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './DataList.css'; // Importiere die CSS-Datei

const DataList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setLoading(true);
    axios.get(`https://reqres.in/api/users?page=${page}&per_page=6`)
      .then(response => {
        setData(response.data.data);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Es gab einen Fehler!', error);
        setLoading(false);
      });
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="data-list">
      <ul>
        {data.map(user => (
          <li key={user.id}>
            <img src={user.avatar} alt={`${user.first_name} ${user.last_name}`} />
            <div className="user-info">
              <p>{user.first_name} {user.last_name}</p>
              <p className="email">{user.email}</p>
              <Link to={`/user/${user.id}`}>Details anzeigen</Link>
            </div>
          </li>
        ))}
      </ul>
      <div className="pagination">
        <button 
          onClick={() => handlePageChange(page - 1)} 
          disabled={page === 1}
        >
          Zurück
        </button>
        <span>Seite {page} von {totalPages}</span>
        <button 
          onClick={() => handlePageChange(page + 1)} 
          disabled={page === totalPages}
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default DataList;