import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import './UserDetail.css'; // Importiere die CSS-Datei

const UserDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`https://reqres.in/api/users/${id}`)
      .then(response => {
        setUser(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Es gab einen Fehler!', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Benutzer nicht gefunden</div>;
  }

  return (
    <div className="user-detail">
      <img src={user.avatar} alt={`${user.first_name} ${user.last_name}`} />
      <h1>{user.first_name} {user.last_name}</h1>
      <p>Email: {user.email}</p>
      <Link to="/">Zurück zur Liste</Link>
    </div>
  );
};

export default UserDetail;